import clsx from 'clsx';
import { Field, FieldHookConfig, useField } from 'formik';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type RadioButtonProps = FieldWrapperPassThroughProps & {
	className?: string;
	displayCols?: boolean;
	handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	fullWidth?: boolean;
};

export const RadioButton = (props: RadioButtonProps & FieldHookConfig<boolean>) => {
	const {
		label,
		className,
		displayCols = true,
		handleChange,
		disabled,
		fullWidth = false,
	} = props;
	const [field, meta] = useField({ ...props, type: 'radio' });
	const { error, touched } = meta;
	const { onChange, ...fields } = field;

	const onChangeExtended = (e: React.ChangeEvent<HTMLInputElement>) => {
		handleChange && handleChange(e);
		onChange(e);
	};

	return (
		<FieldWrapper error={error} touched={touched}>
			<label
				className={`mr-4 
					${displayCols ? '' : 'block mt-3'} 
					${disabled ? 'opacity-25' : ''}
					${fullWidth ? 'w-full border border-primaryBorder rounded px-3 py-3' : ''}
				`}
			>
				{fullWidth && label}
				<Field
					className={clsx(
						className,
						fullWidth ? 'mr-2 mt-[1px] float-right w-6 h-6' : 'mr-2 mb-1'
					)}
					type='radio'
					onChange={onChangeExtended}
					disabled={disabled}
					{...fields}
				/>
				{!fullWidth && label}
			</label>
		</FieldWrapper>
	);
};
