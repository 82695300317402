import { SpinnerFixedPosition } from '@@/components/Elements';
import { useRoutes } from 'react-router-dom';

import { useFetchSessionQuery } from '@/api';
import { AUTH } from '@/features/auth/';
import { cookieConsentFeatureFlag } from '@/globalConsts';
import { loadCookieConsent } from '@/lib/analytics';

import { patientFacingRoutes } from './patientFacing';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

export const AppRoutes = () => {
	const { data: session, error: sessionError, isLoading } = useFetchSessionQuery();
	// check localStorage to ensure user has completed MFA (or not required to)
	const mfaAuthConfirmed =
		localStorage.getItem(AUTH.MFA.STORAGE_KEY) === AUTH.MFA.CONFIRMED_VALUE;
	const routes = session && !sessionError && mfaAuthConfirmed ? protectedRoutes : publicRoutes;

	const element = useRoutes([...routes, ...patientFacingRoutes]);

	const doLoadAnalytics = element?.props?.match?.route?.doNotLoadAnalytics ? false : true;

	if (isLoading) {
		return <SpinnerFixedPosition size='xl' variant='light' />;
	}

	if (cookieConsentFeatureFlag && doLoadAnalytics) {
		loadCookieConsent();
	}
	return <>{element}</>;
};
