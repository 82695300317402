import { Typography, Button } from '../../Elements';
import clsx from 'clsx';
import { ChevronDown } from 'mdi-material-ui';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

// TODO assets should be app specific?
//import EditButton from '@/assets/edit_button.svg';

export type CollapsibleSectionContainerProps = {
	actionButton?: React.ReactElement | undefined | false;
	children: React.ReactElement;
	editLink?: string;
	isEditable: boolean;
	title: string;
	titleIcon?: React.ReactElement;
	isFullWidth?: boolean;
	expandedOnLoad?: boolean;
	classNames?: string;
	dataTestId?: string;
	showBorder?: boolean;
	titleClassNames?: string;
};

export const CollapsibleSectionContainer = (props: CollapsibleSectionContainerProps) => {
	const {
		actionButton,
		children,
		editLink = '',
		isEditable,
		title,
		titleIcon,
		isFullWidth = false,
		expandedOnLoad = true,
		classNames = '',
		dataTestId = '',
		showBorder = true,
		titleClassNames,
	} = props;
	const [isFirstRender, setIsFirstRender] = useState(true);
	const [isExpanded, setIsExpanded] = useState(expandedOnLoad);
	const heightHidden = '0px';
	const heightExpanded = 'auto';
	const initialHeight = expandedOnLoad ? heightExpanded : heightHidden;
	const [containerHeight, setContainerHeight] = useState(initialHeight);
	const contentDivRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		// set to height of content to enable transition
		setContainerHeight(isFirstRender ? initialHeight : `${contentDivRef.current?.offsetHeight || 200}px`);
		setIsFirstRender(false);
	}, [isExpanded]);

	useEffect(() => {
		// we can't transition to/from height auto but don't want to set a fixed height for the content because we'll lose responsiveness
		// this resets the height to either 0 or auto depending on if expanded or not. If auto then we wait for the transition to complete.
		const afterTransitionTime = 600; // wait for transition
		const afterRenderTime = 100; // start transition straight away, but wait for render
		const timeoutTime = isExpanded ? afterTransitionTime : afterRenderTime;
		// if expanded then we set to height auto otherwise set to height hidden
		const newHeight = isExpanded ? heightExpanded : heightHidden;
		setTimeout(() => {
			if (newHeight !== containerHeight) {
				setContainerHeight(newHeight);
			}
		}, timeoutTime);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [containerHeight]);

	return (
		<div
			className={clsx('w-full', showBorder ? '' : 'bg-secondaryBackground p-6', classNames)}
			data-test-id={`${dataTestId}-collapsible-container`}
		>
			<div className='flex gap-4 items-center mb-4'>
				<div className='flex flex-row justify-between items-center w-full'>
					<div
						className={clsx(
							'flex flex-row justify-start items-center',
							isFullWidth ? 'w-full' : ''
						)}
					>
						<button
							type='button'
							onClick={() => {
								setIsExpanded(!isExpanded);
							}}
							className={`${isFullWidth ? 'pl-3' : ''}`}
							data-test-id='collapse-expand-toggle-button'
						>
							{titleIcon}
							<Typography variant='bodyBig' className={`text-primaryTextDark mr-2 text-left ${titleClassNames} ${!!titleIcon ? 'ml-10' : ''}`}>
								{title}
							</Typography>
						</button>
						{isEditable && (
							<Link
								className='flex flex-row justify-center items-center w-7 h-7 rounded-full bg-highlight text-base mr-2'
								to={editLink}
								data-test-id={`${dataTestId}-edit-link`}
							>
								Edit
							</Link>
						)}
						<Button
							variant='icon'
							size='icon'
							onClick={() => {
								setIsExpanded(!isExpanded);
							}}
							className={`w-7 h-7 bg-highlight hover:bg-highlightHover rounded-full ${
								isFullWidth ? 'ml-auto mr-3' : ''
							}`}
						>
							<ChevronDown
								className={`!text-[32px] !transition-transform !ease-in-out !duration-500 ${
									isExpanded
										? 'text-primaryBrand transform rotate-180'
										: 'text-secondaryTextDark rotate-0'
								}`}
							/>
						</Button>
					</div>
					{actionButton}
				</div>
			</div>
			<div
				className={`overflow-hidden transition-height duration-500 ease-linear ${
					isFullWidth ? 'px-3' : ''
				}`}
				style={{ height: containerHeight }}
			>
				<div ref={contentDivRef}>{children}</div>
			</div>
		</div>
	);
};
