import { RadioButton } from './RadioButton';

export type RadioGroupProps = {
	className?: string;
	groupLabel: string;
	radioValues: {
		name: string;
		label: string;
		value: string;
		disabled?: boolean;
	}[];
	displayCols?: boolean;
	fullWidth?: boolean;
	handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	dataTestId?: string;
	disabled?: boolean;
};

export const RadioGroup = (props: RadioGroupProps) => {
	const {
		groupLabel,
		radioValues,
		className,
		displayCols = true,
		handleChange,
		dataTestId = '',
		disabled,
		fullWidth = false,
	} = props;

	const renderRadioButtons = () => {
		return radioValues.map((radioValue, index) => {
			const { label, name, value, disabled: optionDisabled } = radioValue;
			return (
				<RadioButton
					key={index}
					label={label}
					name={name}
					value={value}
					className={className}
					displayCols={displayCols}
					fullWidth={fullWidth}
					handleChange={handleChange}
					disabled={disabled || optionDisabled}
				/>
			);
		});
	};

	return (
		<div className={`${displayCols ? 'flex flex-col' : 'block'}`}>
			<label className={`block text-primaryTextDark ${disabled ? 'opacity-25' : ''}`}>
				{groupLabel}
			</label>
			<div
				role='group'
				aria-labelledby='radio-group'
				className={`${displayCols ? 'flex flex-row align-middle' : 'block'}`}
				data-test-id={dataTestId}
			>
				{renderRadioButtons()}
			</div>
		</div>
	);
};
