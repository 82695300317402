import { FieldHookConfig } from 'formik';
import { useEffect } from 'react';

import { FieldWrapperPassThroughProps } from './FieldWrapper';
import { InputField } from './InputField';
import { FormValues } from './types';
import { Typography } from '../Elements';

export type VerificationCodeProps = FieldWrapperPassThroughProps & {
	additionalMessage?: string | null;
	maxLength?: number;
	values: FormValues;
	setIsVerificationError: (value: boolean) => void;
	handleChange?: (e: React.ChangeEvent<HTMLInputElement> | undefined) => void;
	containerClass?: string
};

export const VerificationCodeField = (props: VerificationCodeProps & FieldHookConfig<string>) => {
	const { name, additionalMessage, maxLength, values, setIsVerificationError, handleChange, containerClass } = props;

	useEffect(() => {
		// reset verification error when value changes
		setIsVerificationError(false);
	}, [values, setIsVerificationError]);

	return (
		<div className={containerClass}>
			<InputField
				type='text'
				label='Verification code'
				name={name}
				maxLength={maxLength}
				autoComplete='one-time-code'
				labelSrOnly={true}
				handleChange={handleChange}
				className='md:w-[288px] md:inline-block'
			/>
			{additionalMessage && (

				<Typography
				variant='bodyBig'
				className='text-secondaryTextLight pt-5'
				>
				{additionalMessage}
			</Typography>
			)}
		</div>
	);
};
