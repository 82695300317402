import { Typography } from '@@/components/Elements';

import { isUs } from '@/globalConsts';

type TermsOfUseProps = {
	type?: string;
};

export const TermsOfUse = (props: TermsOfUseProps) => {
	const termsLink = isUs
		? 'https://umed.io/us/terms-conditions/'
		: 'https://umed.io/terms-conditions/';
	const privacyLink = isUs ? 'https://umed.io/us/privacy/' : 'https://umed.io/privacy/';
	const { type = 'all' } = props;
	return (
		<Typography className='pt-8'>
			By continuing you agree to receive{' '}
			{type === 'sms' ? 'text messages' : 'communications (email and text)'} from uMed and
			agree to the{' '}
			<a href={termsLink} target='_blank' className='text-primaryBrand' rel='noreferrer'>
				Terms of Use
			</a>{' '}
			and{' '}
			<a href={privacyLink} target='_blank' className='text-primaryBrand' rel='noreferrer'>
				Privacy Policy
			</a>
			.
		</Typography>
	);
};
