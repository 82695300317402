import { isUs, phoneNumberPrefix } from '@/globalConsts';

export const formatMobileNumber = (mobile_number: string) => {
	// strip out any non-numeric character
	let mobileNumber = (mobile_number as string).replace(/\D/g, '');

	// Check to see we have a mobile number, if we do and it's a UK number
	// remove the leading 0 and add the country code prefix, if it's a US number
	//  just add the country code prefix
	// if there is no mobile number just return the empty string
	mobileNumber.length >= 1
		? (mobileNumber = phoneNumberPrefix + mobileNumber.slice(isUs ? 0 : 1))
		: mobileNumber;

	return mobileNumber;
};

export const getChosenMethodStr = (method: string) => {
	return import.meta.env.VITE_LOCAL_MFA_CONSOLE_ENABLED === 'true' ? 'console' : method;
};

export const getAfterLoginRedirectUrl = () => {
	const searchParams = new URLSearchParams(window.location.search);
	if (searchParams.has('next')) {
		// next should be a full URL
		const next = searchParams.get('next') as string;
		// the redirect URL comes direct from the browser URL so need to ensure it's valid before redirecting
		// check if the redirect URL is going to the current host or `api.` subdomain of the current host
		try {
			const nextUrl = new URL(next);
			const hostname = window.location.hostname;
			if (nextUrl.hostname === hostname || nextUrl.hostname === `api.${hostname}`) {
				// url is valid
				return next;
			}
		} catch (e) {
			// invalid URL, nothing to do here
		}
	}
	// no valid admin redirect so just go to index page
	return '/';
};
