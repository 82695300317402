import { createColumnHelper } from '@tanstack/react-table';

import { DataTableObject } from '@/components/PatientDataTable';
import { CellContextExtended } from '@/features/patientLists';
import { formatDate } from '@/utils';

import { CancelledPatientsListPreviewPanel } from '../../components/CancelledPatientsReport/CancelledPatientsListPreviewPanel';

import { cancelledPatientListCommonColumns } from './CancelledPatientReportCommonConfig';

const columnHelper = createColumnHelper<DataTableObject>();

export const deceasedPatientListColumns = [
	...cancelledPatientListCommonColumns,
	columnHelper.accessor('opt_out_date', {
		header: () => 'Date marked as deceased',
		cell: (info) => {
			return formatDate(info.getValue() as string);
		},
	}),
	columnHelper.display({
		id: 'action',
		header: () => 'Action',
		cell: (props) => (
			<CancelledPatientsListPreviewPanel
				cellData={props as CellContextExtended}
				type={'deceased'}
			/>
		),
	}),
];

export const deceasedPatientListRowProps = {
	rowSelect: false,
};
