import {
	BaseQueryFn,
	createApi,
	FetchArgs,
	fetchBaseQuery,
	FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

import { API_URL } from '@/config';
import { store } from '@/stores/store';
import storage from '@/utils/storage';

const baseQuery = fetchBaseQuery({
	baseUrl: API_URL,
	prepareHeaders: (headers) => {
		// By default, if we have a token in the store, let's use that for authenticated requests
		const token = storage.getCookie();
		if (token) {
			headers.set('X-CSRFToken', `${token}`);
		}
		return headers;
	},
});

const baseQueryWithAutoLogout: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError
> = async (args, api, extraOptions) => {
	const result = await baseQuery(args, api, extraOptions);
	// if any API returns a 401 Unauthorized area invalidate the current session.
	if (result.error && result.error.status === 401) {
		store.dispatch(baseApi.util.invalidateTags(['session']));
	}
	return result;
};

export const baseApi = createApi({
	reducerPath: 'baseApi',
	baseQuery: baseQueryWithAutoLogout,
	tagTypes: ['session', 'Patient_List'],
	endpoints: () => ({}),
});

// used only for the MFA /verify endpoint as an invalid code returns 401 but we don't want to logout the user
export const baseApiNoAutoLogout = createApi({
	reducerPath: 'baseApi',
	baseQuery: baseQuery,
	tagTypes: ['session'],
	endpoints: () => ({}),
});
