import { Button, Typography } from '@@/components/Elements';
import { FormContainer as Form, PhoneNumberField } from '@@/components/Form';
import { FormActions, FormValues } from '@@/components/Form/types';
import { FormikHelpers, FormikProps } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';

import { formatPhoneNumberDisplay } from '@/features/patientDetails';
import { isUs, phoneNumberPrefix, phoneRegex } from '@/globalConsts';

import {
	useActivateMfaMutation,
	useFetchMobileNumberQuery,
	useUpdateMobileNumberMutation,
} from '../../api/authApi';
import { AUTH } from '../../consts';
import { ActivateMfaRequest, MobileNumberRequest } from '../../types';
import { getChosenMethodStr } from '../../utils';

import { MFAActivateForm } from './MFAActivateForm';
import { TermsOfUse } from './TermsOfUse';

type EnableMFAFormSMSProps = {
	handleBack: () => void;
};

export const EnableMFAFormSMS = (props: EnableMFAFormSMSProps) => {
	const { handleBack } = props;
	const method = 'sms';

	// get user's mobile phone number
	const { data: mobileNumberData } = useFetchMobileNumberQuery();
	const [updateMobileNumber, { isSuccess: mobileNumberUpdateSuccess }] =
		useUpdateMobileNumberMutation();
	const [activateMfa, { data, isSuccess }] = useActivateMfaMutation();

	useEffect(() => {
		// mobile number updated successfully, proceed with sending of code
		if (mobileNumberUpdateSuccess)
			activateMfa({ values: { method: getChosenMethodStr(method) } } as ActivateMfaRequest);
	}, [mobileNumberUpdateSuccess, activateMfa]);

	if (isSuccess) {
		// code has been sent successfully
		if (data && data?.details?.includes('sent')) {
			return <MFAActivateForm method={getChosenMethodStr(method)} />;
		} else {
			return (
				<>
					<Typography variant='display2' className='text-primaryBrand mb-4'>
						{AUTH.ROUTES.LOGIN.TITLE}
					</Typography>
					<Typography variant='display4'>
						Sorry, code could not be sent. Please refresh this page to try again.
					</Typography>
				</>
			);
		}
	}

	const initialValues: FormValues = {
		mobile_number: formatPhoneNumberDisplay(mobileNumberData?.mobile_number) || '',
	};

	const schema = Yup.object().shape({
		mobile_number: Yup.string()
			.label('Mobile number')
			.required()
			.matches(phoneRegex, 'Phone number format is invalid'),
	});
	return (
		<>
			<Typography variant='display2' className='text-primaryBrand mb-2'>
				{AUTH.ROUTES.LOGIN.TITLE}
			</Typography>
			<Typography variant='title4' className='mb-2'>
				Enter phone number
			</Typography>
			<Typography className='mb-4 text-secondaryTextDark'>
				We will send you a one-time verification code. This helps us to secure your account.
			</Typography>
			<Form
				initialValues={initialValues}
				onSubmit={async (
					values: FormValues,
					actions: FormActions & FormikHelpers<FormValues>
				) => {
					// update user's phone number
					await updateMobileNumber({ values } as MobileNumberRequest);
					actions.setSubmitting(false);
				}}
				schema={schema}
				formStyles='space-y-6 sm:max-w-[290px] pt-4'
			>
				{(formikProps: FormikProps<FormValues>) => {
					const { isSubmitting, isValid } = formikProps;
					return (
						<>
							<PhoneNumberField
								label='Mobile number'
								name='mobile_number'
								phoneNumberPrefix={phoneNumberPrefix}
								isUs={isUs}
							/>
							<Button
								variant='secondary'
								type='button'
								onClick={() => {
									handleBack();
								}}
								size='lg'
								className='mt-4 mr-8 float-left'
							>
								Back
							</Button>
							<Button
								variant='primary'
								type='submit'
								isLoading={isSubmitting}
								disabled={!isValid || isSubmitting}
								size='lg'
							>
								Submit
							</Button>
						</>
					);
				}}
			</Form>
			<TermsOfUse type={method} />
		</>
	);
};
