import { Typography } from '@@/components/Elements';
import clsx from 'clsx';
import AlertOutline from 'mdi-material-ui/AlertOutline';
import Check from 'mdi-material-ui/Check';
import Close from 'mdi-material-ui/Close';
import InformationVariant from 'mdi-material-ui/InformationVariant';

export type AlertProps = {
	severity: keyof typeof severityOptions;
	title?: string;
	showCloseButton?: boolean;
	handleClose?: () => void;
	children: string | string[];
	className?: string;
};

const severityOptions = {
	info: {
		icon: <InformationVariant className='text-info' aria-hidden='true' />,
		iconBg: 'bg-white',
		border: 'border-[2px] border-infoBorder',
		background: 'bg-primaryBrandBackground',
		text: 'text-secondaryTextDark',
	},
	success: {
		icon: <Check className='text-success' aria-hidden='true' />,
		iconBg: 'bg-highlight',
		border: 'border border-success',
		background: 'bg-primaryBackground',
		text: 'text-secondaryTextDark',
	},
	warning: {
		icon: <AlertOutline className='text-warning' aria-hidden='true' />,
		iconBg: 'bg-highlight',
		border: 'border border-warning',
		background: 'bg-primaryBackground',
		text: 'text-secondaryTextDark',
	},
	error: {
		icon: <Close className='text-error' aria-hidden='true' />,
		iconBg: 'bg-highlight',
		border: 'border border-error',
		background: 'bg-primaryBackground',
		text: 'text-secondaryTextDark',
	},
	notice: {
		icon: <InformationVariant className='text-info' aria-hidden='true' />,
		iconBg: 'bg-white',
		border: 'border-none rounded-none',
		background: 'bg-primaryBrandBackgroundOpaque',
		text: 'text-primaryTextDark',
	},
};

export const Alert = (props: AlertProps) => {
	const {
		severity = 'success',
		title,
		showCloseButton,
		handleClose,
		children,
		className,
	} = props;

	const renderTitle = () => {
		if (title) {
			return (
				<Typography variant='title4' className='mb-1'>
					{title}
				</Typography>
			);
		}
	};

	return (
		<div
			role='alert'
			className={clsx(
				'flex flex-row items-start p-4 rounded border-solid w-full',
				severityOptions[severity].border,
				severityOptions[severity].background,
				className
			)}
			data-test-id={`alert-${severity}`}
		>
			<div className={clsx('pr-6 flex-shrink-0', title ? 'mt-1' : '')}>
				<div
					className={clsx(
						'rounded-full w-[40px] h-[40px] flex justify-center items-center',
						severityOptions[severity].iconBg
					)}
					aria-hidden='true'
				>
					{severityOptions[severity].icon}
				</div>
			</div>
			<div className='flex-1'>
				{renderTitle()}
				<Typography variant='body' className={severityOptions[severity].text}>
					{children}
				</Typography>
			</div>
			{showCloseButton && handleClose && (
				<div className='ml-4 flex-shrink-0 flex'>
					<button
						type='button'
						className='inline-flex focus:outline-none focus:ring-2 focus:ring-primaryBrand rounded-full p-2 -mt-2 -mr-2 '
						onClick={handleClose}
					>
						<span className='sr-only'>Close</span>
						<Close
							className='text-primaryTextDark'
							fontSize='small'
							aria-hidden='true'
						/>
					</button>
				</div>
			)}
		</div>
	);
};
