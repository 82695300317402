import { Button, Typography } from '@@/components/Elements';
import { InputField, FormContainer as Form, FormActions, FormValues } from '@@/components/Form';
import { FormikHelpers, FormikProps } from 'formik';
import { NavLink, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { Alert } from '@/components/Elements';
import { PasswordField } from '@/components/Form';
import { ErrorObj } from '@/types';

import { useLoginUserMutation } from '../api/authApi';
import { AUTH } from '../consts';
import { LoginRequest } from '../types';

import { EnableMFAForm } from './mfa/EnableMFAForm';
import { MFALoginForm } from './mfa/MFALoginForm';

const initialValues: FormValues = {
	username: '',
	password: '',
};

const schema = Yup.object().shape({
	username: Yup.string().label('Email').required(),
	password: Yup.string().label('Password').required(),
});

export const LoginForm = () => {
	const [loginUser, { data, isSuccess, isError, error }] = useLoginUserMutation();
	const { search } = useLocation();

	// Reset localStorage as new session is being created
	localStorage.removeItem(AUTH.MFA.STORAGE_KEY);

	const renderErrorAlert = () => {
		if (isError)
			return (
				<Alert severity='error'>
					{(error as ErrorObj).data?.detail ||
						AUTH.COMPONENTS.LOGIN.ERROR_ALERT.DESCRIPTION}
				</Alert>
			);
	};

	if (isSuccess) {
		if (data?.ephemeral_token) {
			// MFA is enabled
			return <MFALoginForm ephemeralToken={data.ephemeral_token} method={data.method} />;
		} else {
			// MFA is not enabled
			return <EnableMFAForm />;
		}
	}

	return (
		<>
			<Typography variant='display2' className='text-primaryBrand mb-4'>
				{AUTH.ROUTES.LOGIN.TITLE}
			</Typography>
			<Form
				initialValues={initialValues}
				onSubmit={async (
					values: FormValues,
					actions: FormActions & FormikHelpers<FormValues>
				) => {
					await loginUser(values as LoginRequest);
					actions.setSubmitting(false);
				}}
				schema={schema}
				formStyles='space-y-6 sm:max-w-[378px]'
				buttonText='Sign in'
				buttonStyles='w-full'
			>
				{(formikProps: FormikProps<FormValues>) => {
					const { isSubmitting, isValid, dirty } = formikProps;
					return (
						<>
							{renderErrorAlert()}
							<InputField type='text' label='Username' name='username' width='col3' />
							<PasswordField label='Password' name='password' width='col3' />
							<NavLink
								to={`/forgotten-password${search}`}
								className='flex items-center justify-end pt-4'
							>
								<Typography variant='link'>
									{AUTH.COMPONENTS.LOGIN.FORM.FORGOT_PASSWORD_LINK.LABEL}
								</Typography>
							</NavLink>
							<Button
								variant='primary'
								type='submit'
								isLoading={isSubmitting}
								disabled={!isValid || !dirty || isSubmitting}
								size='lg'
							>
								{AUTH.COMPONENTS.LOGIN.FORM.SUBMIT_BUTTON.LABEL}
							</Button>
						</>
					);
				}}
			</Form>
		</>
	);
};
