/// <reference types="vite/client" />
/// <reference types="vite-plugin-svgr/client" />

import clsx from 'clsx';
import { FieldHookConfig, useField } from 'formik';

import flaguk from '../../assets/flags/uk.svg?url';
import flagus from '../../assets/flags/us.svg?url';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

export type PhoneNumberFieldProps = FieldWrapperPassThroughProps & {
	className?: string;
	placeholder?: string;
	disabled?: boolean;
    isUs?: boolean;
    phoneNumberPrefix?: string;
};

export const PhoneNumberField = (props: PhoneNumberFieldProps & FieldHookConfig<string>) => {
	const { label, name, placeholder, width, className, disabled = false, isUs, phoneNumberPrefix } = props;
	const [field, meta] = useField(props);
	const { error, touched } = meta;

	const flag = isUs ? flagus : flaguk;

	return (
		<FieldWrapper
			error={error}
			touched={touched}
			label={label}
			width={width}
			name={name}
			disabled={disabled}
			className={className}
		>
			<img
				className='w-[24px] absolute left-4 top-[10px]'
				alt='input icon'
				src={flag}
				aria-hidden='true'
			/>
			<span className='absolute left-12 top-[11px] pr-2 border-primaryBorder border-r-[1px]'>
				{phoneNumberPrefix}
			</span>
			<input
				type='text'
				id={name}
				placeholder={placeholder}
				{...field}
				className={clsx(
					'flex-1 min-w-0 block w-full h-11 p-3 text-base rounded border-primaryBorder placeholder:text-base placeholder:text-disabledText',
					isUs ? 'pl-20' : 'pl-24',
					error && touched
						? 'text-error border-error placeholder:text-error focus:ring-error focus:border-error'
						: 'focus:ring-primaryBrand focus:border-primaryBrand',
					disabled
						? 'bg-disabledBackground border-primaryBorder cursor-not-allowed text-primaryTextDark'
						: ''
				)}
				disabled={disabled}
				aria-invalid='true'
				aria-describedby='phone-number-error'
			/>
		</FieldWrapper>
	);
};
