import { Button, Typography, Alert } from '@@/components/Elements';
import {
	FormContainer as Form,
	VerificationCodeField,
	FormActions,
	FormValues,
} from '@@/components/Form';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { FormikHelpers, FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import IconSent from '@/assets/icon_sent.svg';

import { useActivateConfirmMfaMutation, useActivateMfaMutation } from '../../api/authApi';
import { AUTH } from '../../consts';
import { ActivateConfirmMfaRequest } from '../../types';

import { LockedAccount } from './LockedAccount';
import { MFAEnabled } from './MFAEnabled';
import { ResendCode } from './ResendCode';

const initialValues: FormValues = {
	code: '',
};

const codeLength = 6;
const schema = Yup.object().shape({
	code: Yup.string()
		.required('Verification code is required')
		.matches(/^[0-9]+$/, 'Code must only be numbers')
		.min(codeLength, 'Code must be exactly 6 digits')
		.max(codeLength, 'Code must be exactly 6 digits'),
});

type MFAActivateFormProps = {
	method: string;
};

export const MFAActivateForm = (props: MFAActivateFormProps) => {
	const { method } = props;
	const [activateConfirmMfa, { data, isSuccess, isError, error }] =
		useActivateConfirmMfaMutation();

	const [isVerificationError, setIsVerificationError] = useState(false);
	const [codeResentConfirm, setCodeResentConfirm] = useState(false);

	useEffect(() => {
		if (isSuccess) {
			localStorage.setItem(AUTH.MFA.STORAGE_KEY, AUTH.MFA.CONFIRMED_VALUE);
		}
	}, [isSuccess]);

	if (isError && (error as FetchBaseQueryError).status === 429) {
		// too many requests, display error to prevent further requests
		return <LockedAccount />;
	}

	if (isSuccess && data) {
		return <MFAEnabled data={data} />;
	}

	const methodText = method === 'email' ? 'email' : method === 'sms' ? 'phone number' : 'console';
	return (
		<>
			<Typography variant='display2' className='text-primaryBrand mb-2'>
				{AUTH.ROUTES.LOGIN.TITLE}
			</Typography>
			{isVerificationError ? (
				<div className='mb-8'>
					<Alert severity='error' title='Verification code error'>
						The code is invalid or expired. Please try again or request a new code.
					</Alert>
				</div>
			) : (
				codeResentConfirm && (
					<div className='mb-8'>
						<Alert
							severity='success'
							customIcon={<img src={IconSent} alt='sent' className='w-5 h-5' />}
						>
							New verification code has been sent.
						</Alert>
					</div>
				)
			)}
			<Typography variant='title4' className='mb-4 text-secondaryTextDark'>
				Please enter the 6-digit code we have sent to your {methodText}.
			</Typography>
			<Form
				initialValues={initialValues}
				onSubmit={async (
					values: FormValues,
					actions: FormActions & FormikHelpers<FormValues>
				) => {
					await activateConfirmMfa({
						method,
						values,
					} as ActivateConfirmMfaRequest);
					setCodeResentConfirm(false);
					actions.setSubmitting(false);
				}}
				schema={schema}
			>
				{(formikProps: FormikProps<FormValues>) => {
					const { isSubmitting, isValid, dirty, values } = formikProps;
					return (
						<>
							<VerificationCodeField
								name='code'
								maxLength={codeLength}
								values={values}
								setIsVerificationError={setIsVerificationError}
							/>
							<Button
								variant='primary'
								type='submit'
								isLoading={isSubmitting}
								disabled={!isValid || !dirty || isSubmitting}
								size='lg'
								className='mt-4'
							>
								Submit code
							</Button>
							<ResendCode
								verifyError={isError}
								setIsVerificationError={setIsVerificationError}
								setCodeResentConfirm={setCodeResentConfirm}
								resendMutation={useActivateMfaMutation}
								requestProps={{ values: { method } }}
							/>
						</>
					);
				}}
			</Form>
		</>
	);
};
