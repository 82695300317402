import { Button } from '@@/components/Elements';
import { FormContainer as Form, FormActions, FormValues } from '@@/components/Form';
import { FormikHelpers, FormikProps } from 'formik';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';

import { PasswordField } from '@/components/Form';
import { addNotification } from '@/components/Notifications/notificationsSlice';
import { baseApi } from '@/lib/rtkQuery/baseApi';

import { useNewPasswordConfirmationMutation } from '../api/authApi';
import { AUTH } from '../consts';
import { NewPasswordRequest } from '../types';

import { PasswordChecker } from './PasswordChecker';
import { UserTermsAndConditions } from './UserTermsAndConditions';

type ResetPasswordProps = {
	token: string;
	isNewUser: boolean;
	termsAndConditionError: (e: boolean) => void;
};

const schema = Yup.object().shape({
	password: Yup.string()
		.min(
			10,
			AUTH.COMPONENTS.RESET_PASSWORD.FORM.VALIDATION.ERRORS.PASSWORD_MINIMUM_REQUIREMENTS
		)
		.matches(
			/(?=.*\d)(?=.*[!@£$^&%#*+_-])(?=.*[a-z])(?=.*[A-Z])/,
			AUTH.COMPONENTS.RESET_PASSWORD.FORM.VALIDATION.ERRORS.PASSWORD_MINIMUM_REQUIREMENTS
		)
		.required(AUTH.COMPONENTS.RESET_PASSWORD.FORM.VALIDATION.ERRORS.PASSWORD_REQUIRED),
	termsAccepted: Yup.boolean().default(false).oneOf([true], ''),
});

export const ResetPasswordForm: React.FC<ResetPasswordProps> = ({
	token,
	isNewUser,
	termsAndConditionError,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [newPassword, { isSuccess }] = useNewPasswordConfirmationMutation();
	const initialValues: FormValues = {
		password: '',
		termsAccepted: isNewUser ? false : true,
		token: token,
	};
	useEffect(() => {
		if (isSuccess) {
			dispatch(
				addNotification({
					type: 'success',
					title: AUTH.COMPONENTS.RESET_PASSWORD.SUCCESS_NOTIFICATION.TITLE,
					message: AUTH.COMPONENTS.RESET_PASSWORD.SUCCESS_NOTIFICATION.BODY,
					timeoutLength:
						AUTH.COMPONENTS.RESET_PASSWORD.SUCCESS_NOTIFICATION.TIMEOUT_LENGTH,
				})
			);
			dispatch(baseApi.util.invalidateTags(['session']));
			navigate('/');
		}
	}, [dispatch, isSuccess, navigate]);
	return (
		<Form
			initialValues={initialValues}
			onSubmit={async (
				values: FormValues,
				actions: FormActions & FormikHelpers<FormValues>
			) => {
				await newPassword({
					token: token,
					password: (values as NewPasswordRequest).password,
				});
				actions.setSubmitting(false);
			}}
			schema={schema}
			validate={(values: FormValues) => {
				if (values.termsAccepted) {
					termsAndConditionError(false);
				} else {
					termsAndConditionError(true);
				}
			}}
		>
			{(formikProps: FormikProps<FormValues>) => {
				const { isSubmitting, isValid, dirty, values } = formikProps;
				return (
					<>
						<PasswordField label='Password' name='password' width='col3' />
						<PasswordChecker password={(values as NewPasswordRequest).password} />
						{isNewUser ? <UserTermsAndConditions name='termsAccepted' /> : null}
						<div className='pt-5'>
							<div className='flex justify-start'>
								<Button
									variant='primary'
									type='submit'
									className='w-full'
									isLoading={isSubmitting}
									disabled={!isValid || !dirty}
								>
									{AUTH.COMPONENTS.RESET_PASSWORD.FORM.SUBMIT_BUTTON.LABEL}
								</Button>
							</div>
						</div>
					</>
				);
			}}
		</Form>
	);
};
