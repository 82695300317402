import { parse } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import { FilterValuesObj, getActiveFilters } from '@/components/PatientDataTable';
import {
	FetchHciListsRequest,
	FetchHciListsResponseObject,
} from '@/features/healthCareInstitution';
import { baseApi } from '@/lib/rtkQuery/baseApi';

import {
	FetchAllPatientListsRequest,
	CreatePatientListRequest,
	CreatePatientResponseObject,
	CriteriaObject,
	FetchListPatientsRequest,
	FetchListPatientsResponseObject,
	FetchListDetailsResponseObject,
	FetchPatientListRequest,
	FetchPatientListResponseObject,
	FetchSearchCriteriaListResponseObject,
	FetchSurveyListResponseObject,
	SearchCriteriaObject,
	FetchAllPatientListsResponse,
	SendSurveyResponseObj,
	SendSurveyRequest,
	PatientListFilterRequest,
	SurveyFiltersType,
} from '../types';

const formatFormData = (filtersForm: PatientListFilterRequest) => {
	// moves 'survey_needs_assistance' filter to within survey fliters
	if (Object.prototype.hasOwnProperty.call(filtersForm, 'survey_needs_assistance')) {
		// remove the key as it's no longer required
		const newStatusValue = filtersForm.survey_needs_assistance || 'completed';
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const filtersFormWithoutKey = (({ survey_needs_assistance, ...otherKeys }) => otherKeys)(
			filtersForm
		);
		filtersFormWithoutKey.survey_filters = filtersFormWithoutKey.survey_filters?.map(
			(surveyFilter) => {
				const newSurveyFilters = { ...(surveyFilter as SurveyFiltersType) };
				if (newSurveyFilters.survey_status === 'completed') {
					// update the completed survey with the new value
					newSurveyFilters.survey_status = newStatusValue;
				}
				return newSurveyFilters;
			}
		);
		return filtersFormWithoutKey;
	}
	return filtersForm;
};

const formatListResponse = (listResponse: FetchListDetailsResponseObject) => {
	// separate out 'survey_needs_assistance' filter from within survey fliters
	const newResponse = { ...listResponse };
	let surveyNeedsAssistance;
	newResponse.filters.survey_filters = (
		newResponse.filters.survey_filters as SurveyFiltersType[]
	)?.map((surveyFilter) => {
		const newSurveyFilters = { ...(surveyFilter as SurveyFiltersType) };
		if (newSurveyFilters.survey_status === 'completed_with_assistance') {
			// update the completed survey with the new value
			newSurveyFilters.survey_status = 'completed';
			surveyNeedsAssistance = 'completed_with_assistance';
		} else if (newSurveyFilters.survey_status === 'completed_no_assistance') {
			// update the completed survey with the new value
			newSurveyFilters.survey_status = 'completed';
			surveyNeedsAssistance = 'completed_no_assistance';
		}
		return newSurveyFilters;
	});
	if (surveyNeedsAssistance) {
		// add the new filter value
		newResponse.filters.survey_needs_assistance = surveyNeedsAssistance;
	}
	return newResponse;
};

export const patientListApi = baseApi
	.enhanceEndpoints({
		addTagTypes: ['patient_list', 'all_patient_list', 'hci_lists'],
	})
	.injectEndpoints({
		endpoints: (build) => ({
			fetchPatientList: build.query<FetchPatientListResponseObject, FetchPatientListRequest>({
				query: ({ search_value, page_size, page, filtersForm }) => {
					const searchValue = search_value ? search_value : '';
					const filters = formatFormData(filtersForm);
					return {
						url: 'v1/patient-search/',
						method: 'POST',
						body: {
							page,
							page_size,
							filters: {
								...filters,
								search: searchValue,
							},
						},
					};
				},
				transformResponse: (response: FetchPatientListResponseObject) => {
					return {
						...response,
						results: response.results.map((result) => {
							return {
								...result,
								search_criteria: [
									...new Set(
										(result.search_criteria as SearchCriteriaObject[])
											.map((item: SearchCriteriaObject) => {
												return item.criteria.filter(
													(item: CriteriaObject) => item.name?.length > 0
												);
											})
											.flat()
									),
								],
							};
						}),
					};
				},
				providesTags: ['patient_list'],
			}),
			createPatientList: build.mutation<
				CreatePatientResponseObject,
				CreatePatientListRequest
			>({
				query: (data) => {
					const activeFilters = getActiveFilters(data.filters as FilterValuesObj, [
						'survey_filters',
						'contact_filters',
					]);
					const searchValue = data.search_value ? data.search_value : '';
					const filters = formatFormData(activeFilters);
					return {
						url: 'v1/patient-list/',
						method: 'POST',
						body: {
							name: data.name,
							filters: {
								...filters,
								search: searchValue,
							},
						},
					};
				},
				invalidatesTags: ['all_patient_list'],
			}),
			fetchSearchCriteria: build.query<FetchSearchCriteriaListResponseObject, void>({
				query: () => {
					return {
						url: `v1/search-criteria/?ordering=name&page=1&page_size=500`,
					};
				},
				transformResponse: (response: FetchSearchCriteriaListResponseObject) => {
					return Array.isArray(response)
						? { results: response, total: response.length }
						: response;
				},
			}),
			fetchSurveyList: build.query<FetchSurveyListResponseObject, void>({
				query: () => {
					return {
						url: `v1/study/survey-version/?all=true`,
					};
				},
				transformResponse: (response: FetchSurveyListResponseObject) => {
					return Array.isArray(response)
						? { results: response, total: response.length }
						: response;
				},
			}),
			fetchAllPatientLists: build.query<
				FetchAllPatientListsResponse,
				FetchAllPatientListsRequest
			>({
				query: ({ page, page_size, ordering, search_value, archived = false }) => {
					const encoded_search_value = encodeURIComponent(
						search_value ? search_value : ''
					);
					return {
						url: `v1/patient-list/?page=${page}&page_size=${page_size}&ordering=${ordering}&search=${encoded_search_value}&archived=${archived}`,
						method: 'GET',
					};
				},
				providesTags: ['all_patient_list'],
			}),
			fetchListDetails: build.query<FetchListDetailsResponseObject, string>({
				query: (list_identifier) => {
					return {
						url: `v1/patient-list/${list_identifier}`,
					};
				},
				transformResponse: (response: FetchListDetailsResponseObject) =>
					formatListResponse(response),
			}),
			fetchListPatients: build.query<
				FetchListPatientsResponseObject,
				FetchListPatientsRequest
			>({
				query: ({ id, search_value, page, page_size }) => {
					const encoded_search_value = encodeURIComponent(
						search_value ? search_value : ''
					);
					return {
						url: `v1/patient-list/${id}/patients/?search=${encoded_search_value}&page=${page}&page_size=${page_size}`,
					};
				},
				transformResponse: (response: FetchListPatientsResponseObject) => {
					return {
						...response,
						results: response.results.map((result) => {
							return {
								...result,
								search_criteria: [
									...new Set(
										(result.search_criteria as SearchCriteriaObject[])
											.map((item: SearchCriteriaObject) => {
												return item.criteria.filter(
													(item: CriteriaObject) => item.name?.length > 0
												);
											})
											.flat()
									),
								],
							};
						}),
					};
				},
				providesTags: ['patient_list'],
			}),
			sendSurvey: build.mutation<SendSurveyResponseObj, SendSurveyRequest>({
				query: ({ values, list_id }) => {
					// convert date/time values to Date object (in local time)
					const dateFormatInput = 'y-MM-dd HH:mm:ss'; // YYYY-MM-DD HH:MM:SS
					const sendAtDateTime = parse(
						`${values.send_date} ${values.send_time}`,
						dateFormatInput,
						new Date()
					);
					// convert date/time to UTC time string
					const dateFormatOutput = `yyyy-MM-dd'T'HH:mm:ss'Z'`; //e.g. 2024-06-25T07:43:48Z
					const utcDateTimeStr = formatInTimeZone(
						sendAtDateTime,
						'UTC',
						dateFormatOutput
					);
					return {
						url: `v1/patient-list/${list_id}/send-survey/`,
						method: 'POST',
						body: {
							survey_version: values.survey_version,
							send_at: utcDateTimeStr,
						},
					};
				},
			}),
			archiveList: build.mutation<void, string>({
				query: (list_identifier) => {
					return {
						url: `v1/patient-list/${list_identifier}/`,
						method: 'PATCH',
						body: {
							archived: true,
						},
					};
				},
				invalidatesTags: ['all_patient_list'],
			}),
			fetchHciListsFilter: build.query<FetchHciListsResponseObject, FetchHciListsRequest>({
				query: () => {
					return {
						url: `v1/healthcare-provider/provider?all=true`,
						method: 'GET',
					};
				},
				transformResponse: (response: FetchHciListsResponseObject) => {
					return Array.isArray(response)
						? { results: response, total: response.length }
						: response;
				},
				providesTags: ['hci_lists'],
			}),
			fetchAllListsFilter: build.query<
				FetchAllPatientListsResponse,
				FetchAllPatientListsRequest
			>({
				query: () => {
					return {
						url: `v1/patient-list/?all=true&archived=false`,
						method: 'GET',
					};
				},
				transformResponse: (response: FetchAllPatientListsResponse) => {
					return Array.isArray(response)
						? { results: response, total: response.length }
						: response;
				},
				providesTags: ['all_patient_list'],
			}),
		}),
	});

export const {
	useFetchPatientListQuery,
	useCreatePatientListMutation,
	useFetchSearchCriteriaQuery,
	useFetchSurveyListQuery,
	useFetchAllPatientListsQuery,
	useFetchListDetailsQuery,
	useFetchListPatientsQuery,
	useSendSurveyMutation,
	useArchiveListMutation,
	useFetchHciListsFilterQuery,
	useFetchAllListsFilterQuery,
} = patientListApi;
