import { createColumnHelper } from '@tanstack/react-table';

import { DataTableObject, PersonDataObject } from '@/components/PatientDataTable';
import { renderHciNameStatus, StudiesObject } from '@/features/patientLists';

const columnHelper = createColumnHelper<DataTableObject>();

type displayArrayItemTypes = StudiesObject;

const renderHci = (data: StudiesObject) => {
	return renderHciNameStatus(data.hci_name, data.hci_active);
};

const displayArrayItem = (
	arr: displayArrayItemTypes[],
	accessor: string,
	formatFunction?: (data: StudiesObject) => JSX.Element
) => {
	if (!arr || arr.length === 0) return <span>-</span>;
	return arr.length > 1
		? `+${arr.length}`
		: typeof formatFunction === 'function'
		? formatFunction(arr[0] as StudiesObject)
		: arr[0][accessor as keyof displayArrayItemTypes];
};

export const cancelledPatientListCommonColumns = [
	columnHelper.display({
		id: 'study_name',
		header: () => 'Study/Cohort',
		cell: (props) => displayArrayItem((props.row.original as PersonDataObject).studies, 'name'),
	}),
	columnHelper.display({
		id: 'umed_id',
		header: () => 'uMed ID',
		cell: (props) =>
			displayArrayItem(
				(props.row.original as PersonDataObject).studies,
				'patient_identifier'
			),
	}),
	columnHelper.display({
		id: 'hci_name',
		header: () => 'HCI',
		cell: (props) =>
			displayArrayItem(
				(props.row.original as PersonDataObject).studies,
				'hci_name',
				renderHci
			),
	}),
];

export const cancelledPatientListTabValueDefault = 0;
