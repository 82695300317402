import { Button, Spinner, Typography } from '@@/components/Elements';
import { FormContainer as Form, RadioGroup } from '@@/components/Form';
import { FormActions, FormValues } from '@@/components/Form/types';
import { FormikHelpers, FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { useFetchFeatureFlagsQuery } from '@/api';
import { addNotification } from '@/components/Notifications/notificationsSlice';
import { MFA_NOTICE_KEY } from '@/globalConsts';
import { baseApi } from '@/lib/rtkQuery/baseApi';
import { SwitchObject } from '@/types';

import { useActivateMfaMutation } from '../../api/authApi';
import { AUTH } from '../../consts';
import { ActivateMfaRequest } from '../../types';
import { getChosenMethodStr, getAfterLoginRedirectUrl } from '../../utils';

import { EnableMFAFormSMS } from './EnableMFAFormSMS';
import { MFAActivateForm } from './MFAActivateForm';
import { TermsOfUse } from './TermsOfUse';

const initialValues: FormValues = {
	method: '',
};

const schema = Yup.object().shape({
	method: Yup.string().label('Preferred method').required(),
});

export const EnableMFAForm = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	// check if user is enrolled in MFA
	const {
		data: featureFlagData,
		isSuccess: featureFlagIsSuccess,
		isError: featureFlagIsError,
	} = useFetchFeatureFlagsQuery();
	const [chosenMethod, setChosenMethod] = useState<string>('');
	const [activateMfa, { data, isSuccess }] = useActivateMfaMutation();

	useEffect(() => {
		const isMfaFlagActive = (featureFlagData?.flags?.mfa_enrolled as SwitchObject)?.is_active;
		const doBypass = featureFlagIsError || (featureFlagIsSuccess && !isMfaFlagActive);
		if (doBypass) {
			// if we've reached here either the user is not enrolled on MFA, MFA is not yet active on the site or there was an error
			// just let the normal authentication flow continue
			dispatch(
				addNotification({
					type: 'success',
					title: AUTH.COMPONENTS.LOGIN.SUCCESS_NOTIFICATION.TITLE,
					message: AUTH.COMPONENTS.LOGIN.SUCCESS_NOTIFICATION.BODY,
					timeoutLength: AUTH.COMPONENTS.LOGIN.SUCCESS_NOTIFICATION.TIMEOUT_LENGTH,
				})
			);
			localStorage.setItem(AUTH.MFA.STORAGE_KEY, AUTH.MFA.CONFIRMED_VALUE);
			// ensure the MFA notice banner is shown on each login
			localStorage.setItem(MFA_NOTICE_KEY, 'true');
			const redirectUrl = getAfterLoginRedirectUrl();
			if (redirectUrl.includes('http')) {
				// redirect to external URL
				window.location.replace(redirectUrl);
			} else {
				// go to index page
				dispatch(baseApi.util.invalidateTags(['session']));
				navigate('/');
			}
		}
	}, [dispatch, navigate, featureFlagData, featureFlagIsError, featureFlagIsSuccess]);

	if (isSuccess) {
		// user has chosen preferred method
		if (data && data?.details?.includes('sent')) {
			return <MFAActivateForm method={getChosenMethodStr(chosenMethod)} />;
		} else {
			return (
				<>
					<Typography variant='display2' className='text-primaryBrand mb-4'>
						{AUTH.ROUTES.LOGIN.TITLE}
					</Typography>
					<Typography variant='display4'>
						Sorry, code could not be sent. Please refresh this page to try again.
					</Typography>
				</>
			);
		}
	}

	if (featureFlagData) {
		// check if user is enrolled in MFA
		if ((featureFlagData?.flags?.mfa_enrolled as SwitchObject)?.is_active) {
			if (chosenMethod === 'sms')
				return <EnableMFAFormSMS handleBack={() => setChosenMethod('')} />;
			return (
				<>
					<Typography variant='display2' className='text-primaryBrand mb-2'>
						{AUTH.ROUTES.LOGIN.TITLE}
					</Typography>
					<Typography variant='title4' className='mb-4'>
						You must enable Multi-Factor Authentication (MFA) to continue
					</Typography>
					<Form
						initialValues={initialValues}
						onSubmit={async (
							values: FormValues,
							actions: FormActions & FormikHelpers<FormValues>
						) => {
							// check if SMS
							const valuesForRequest = {
								method: getChosenMethodStr(values.method as string),
							};
							setChosenMethod(values.method as string);
							if (values.method !== 'sms') {
								// only submit the form if not SMS.
								// if is SMS then conditional logic below will display an additonal form for phone number
								await activateMfa({
									values: valuesForRequest,
								} as ActivateMfaRequest);
							}
							actions.setSubmitting(false);
						}}
						schema={schema}
						formStyles='space-y-6 sm:max-w-[290px] pt-4'
					>
						{(formikProps: FormikProps<FormValues>) => {
							const { isSubmitting, isValid, dirty } = formikProps;
							return (
								<>
									<RadioGroup
										groupLabel='Select preferred method'
										className='col6'
										radioValues={[
											{
												name: 'method',
												label: 'Email',
												value: 'email',
											},
											{
												name: 'method',
												label: 'SMS',
												value: 'sms',
											},
										]}
										displayCols={false}
										fullWidth={true}
									/>
									<Button
										variant='primary'
										type='submit'
										isLoading={isSubmitting}
										disabled={!isValid || !dirty || isSubmitting}
										size='lg'
									>
										Submit
									</Button>
								</>
							);
						}}
					</Form>
					<TermsOfUse />
				</>
			);
		}
	}
	return <Spinner variant='light' size='xl' className='' />;
};
