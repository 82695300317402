import { Button, Typography } from '@@/components/Elements';
import CheckCircleOutline from 'mdi-material-ui/CheckCircleOutline';
import ContentCopy from 'mdi-material-ui/ContentCopy';
import TrayArrowDown from 'mdi-material-ui/TrayArrowDown';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import IconCheck from '@/assets/icon_green_check.svg';

import { AUTH } from '../../consts';
import { ActivateConfirmMfaResponse } from '../../types';
import { getAfterLoginRedirectUrl } from '../../utils';

type MFAActivateFormProps = {
	data: ActivateConfirmMfaResponse;
};

export const MFAEnabled = (props: MFAActivateFormProps) => {
	const { data } = props;
	const backupCodeArr = data?.backup_codes;
	const backupCodeStr = backupCodeArr.join('\r\n');

	const [copyComplete, setCopyComplete] = useState(false);
	const copyToClipboard = async () => {
		// copy the recovery codes to the clipboard
		try {
			await navigator.clipboard.writeText(backupCodeStr);
			// set the copy complete state to true to show the success icon
			setCopyComplete(true);
		} catch (err) {
			setCopyComplete(false);
		}
	};

	useEffect(() => {
		if (copyComplete) {
			setTimeout(() => {
				// reset the copy complete state after 3 seconds to return the icon to the copy icon
				setCopyComplete(false);
			}, 3000);
		}
	}, [copyComplete]);

	const downloadTxtFile = () => {
		// download the recovery codes as a .txt file
		const element = document.createElement('a');
		// create a new blob file with the recovery codes
		const file = new Blob([backupCodeStr], { type: 'text/plain' });
		element.href = URL.createObjectURL(file);
		element.download = 'recoveryCodes.txt';
		document.body.appendChild(element);
		// trigger a click event on the element to download the file
		element.click();
	};

	const continueURL = getAfterLoginRedirectUrl();
	return (
		<>
			<Typography variant='display2' className='text-primaryBrand mb-2'>
				{AUTH.ROUTES.LOGIN.TITLE}
			</Typography>
			<Typography variant='title4' className='mt-4 mb-8'>
				<img src={IconCheck} alt='' className='w-8 h-8 inline-block mr-4' />
				MFA successfully enabled.
			</Typography>
			{backupCodeArr.length && (
				<>
					<Typography className='my-4 font-bold'>
						Please store your back-up codes:
					</Typography>
					<div className='inline-grid md:grid-cols-2 gap-y-3 gap-x-8'>
						{data.backup_codes.map((code: string, index: number) => (
							<Typography variant='bodyBig' key={index}>
								{code}
							</Typography>
						))}
					</div>
					<Typography className='mt-4 mb-4'>
						Please store your recovery codes in a <strong>secure place</strong>. Backup
						codes are one-time-use codes that provide a fallback option for logging into
						your account if you lose access to your primary MFA method.
					</Typography>
					<div className='my-6 inline-grid md:grid-cols-2 gap-y-4 gap-x-10'>
						<div>
							<Button
								variant='primaryIcon'
								size='inline'
								startIcon={
									<TrayArrowDown
										fontSize='small'
										className='mr-2 text-secondaryTextDark'
									/>
								}
								onClick={downloadTxtFile}
							>
								Download as .txt file
							</Button>
						</div>
						<div>
							<Button
								variant='primaryIcon'
								size='inline'
								startIcon={
									copyComplete ? (
										<CheckCircleOutline
											fontSize='small'
											className='mr-2 text-success'
										/>
									) : (
										<ContentCopy
											fontSize='small'
											className='mr-2 text-secondaryTextDark'
										/>
									)
								}
								onClick={copyToClipboard}
							>
								{copyComplete ? 'Copied to clipboard' : 'Copy codes'}
							</Button>
						</div>
					</div>
				</>
			)}
			<div className='block mt-4'>
				{continueURL.includes('http') ? (
					<a href={continueURL} className='inline-block'>
						<Button>Continue</Button>
					</a>
				) : (
					<Link reloadDocument={true} to={continueURL} className='inline-block'>
						<Button>Continue</Button>
					</Link>
				)}
			</div>
		</>
	);
};
