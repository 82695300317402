import clsx from 'clsx';
import { FieldHookConfig, useField } from 'formik';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

export type InputFieldProps = FieldWrapperPassThroughProps & {
	type?: 'text' | 'email' | 'password';
	className?: string;
	placeholder?: string;
	disabled?: boolean;
	icon?: string;
	maxLength?: number;
	autoComplete?: string;
	labelSrOnly?: boolean;
	errorMsg?: string;
	handleChange?: (e: React.ChangeEvent<HTMLInputElement> | undefined) => void;
	handleBlur?: (e: React.FocusEvent<HTMLInputElement> | undefined) => void;
};

export const InputField = (props: InputFieldProps & FieldHookConfig<string>) => {
	const {
		type,
		label,
		name,
		placeholder,
		width,
		className,
		disabled = false,
		icon,
		maxLength,
		autoComplete,
		labelSrOnly = false,
		errorMsg,
		handleChange,
		handleBlur,
	} = props;
	const [field, meta] = useField(props);
	const { error, touched } = meta;

	return (
		<FieldWrapper
			error={error || errorMsg}
			touched={touched}
			label={label}
			width={width}
			name={name}
			disabled={disabled}
			className={className}
			labelSrOnly={labelSrOnly}
		>
			{icon && (
				<img
					className='w-[24px] absolute left-4 top-[10px]'
					alt='input icon'
					src={icon}
					aria-hidden='true'
				/>
			)}
			<input
				type={type}
				id={name}
				placeholder={placeholder}
				autoComplete={autoComplete}
				{...field}
				className={clsx(
					'flex-1 min-w-0 block w-full h-11 p-3 text-base rounded border-primaryBorder placeholder:text-base placeholder:text-disabledText',
					icon ? 'pl-12' : '',
					error && touched
						? 'text-error border-error placeholder:text-error focus:ring-error focus:border-error'
						: 'focus:ring-primaryBrand focus:border-primaryBrand',
					disabled
						? 'bg-disabledBackground border-primaryBorder cursor-not-allowed text-primaryTextDark'
						: ''
				)}
				disabled={disabled}
				aria-invalid='true'
				aria-describedby='email-error'
				maxLength={maxLength}
				onChange={(e) => {
					handleChange?.(e);
					field.onChange(e);
				}}
				onBlur={(e) => {
					handleBlur?.(e);
					field.onBlur(e);
				}}
			/>
		</FieldWrapper>
	);
};
